import React, { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { Container, Typography, SEO } from '../components';
import Card from '@material-ui/core/Card';
import { Swipeable, EventData } from 'react-swipeable';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import styled, { css, keyframes } from 'styled-components';
import { Description } from '../components/Description';

import { data } from '../lib/data';
import { ObjectLiteral } from '../type';

type HomePagePropsType = {
    data: any;
};

type AnimationType = 'enter' | 'leaveRight' | 'leaveLeft';

type AnimationStructureType = ObjectLiteral<{
    type: AnimationType;
    timer: number;
}>;

type SetAnimationFunctionType = (animations: AnimationStructureType) => void;

const formatNumber = (value: number) => value.toString().length === 1 ? value.toString().padStart(2, 0) : value.toString();

const chop = (value: string) => value.replace(/(^\n|\n$)/g, '');

export const Cards = styled.div`
    margin-bottom: 1rem;
    position: relative;
    height: 25rem;
`;

export const Buttons = styled.div`
    text-align: right;
    & *:not(:last-child) {
        margin-right: 1rem;
    } 
`;

const leaveLeftAnimation = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(-2rem);
    opacity: 0;
  }
`;

const leaveRightAnimation = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(2rem);
    opacity: 0;
  }
`;

const enterAnimation = keyframes`
  from {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const placeAnimation = (animations: AnimationStructureType, type: AnimationType, index: number, setAnimations: SetAnimationFunctionType) => {

    if (animations[index]) {
        clearTimeout(animations[index].timer);
    }

    animations[index] = {
        timer: setTimeout(() => {
            if (animations[index]) {
                delete animations[index];
                setAnimations({
                    ...animations,
                });
            }
        }, 300),
        type,
    };

    setAnimations({
        ...animations,
    });
};

const animateCardSwap = (animations: AnimationStructureType, cardToHide: number, cardToShow: number, direction: AnimationType, setAnimations: SetAnimationFunctionType) => {
    placeAnimation(animations, direction, cardToHide, setAnimations);
    placeAnimation(animations, 'enter', cardToShow, setAnimations);
};

const goLeft = (displayedCard: number, animations: AnimationStructureType, setAnimations: SetAnimationFunctionType, setDisplayedCard: (card: number) => void) => {
    if (displayedCard > 0) {
        animateCardSwap(animations, displayedCard, displayedCard - 1, 'leaveRight', setAnimations);
        setDisplayedCard(displayedCard - 1);
    } else {
        animateCardSwap(animations, displayedCard, data.length - 1, 'leaveRight', setAnimations);
        setDisplayedCard(data.length - 1);
    }
};

const goRight = (displayedCard: number, animations: AnimationStructureType, setAnimations: SetAnimationFunctionType, setDisplayedCard: (card: number) => void) => {
    if (displayedCard < data.length - 1) {
        animateCardSwap(animations, displayedCard, displayedCard + 1, 'leaveLeft', setAnimations);
        setDisplayedCard(displayedCard + 1);
    } else {
        animateCardSwap(animations, displayedCard, 0, 'leaveRight', setAnimations);
        setDisplayedCard(0);
    }
};

export const CustomCard = styled(Card)<{ visible: boolean; leaveLeft: boolean; leaveRight: boolean; enter: boolean; }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-fill-mode: forwards;
    
    opacity: 0;
    ${({ visible }) => visible ? css`opacity: 1;` : ''}
    
    ${({ leaveLeft }) => !!leaveLeft && css` animation: ${leaveLeftAnimation} 300ms ease; `}
    ${({ leaveRight }) => !!leaveRight && css` animation: ${leaveRightAnimation} 300ms ease; `}
    ${({ enter }) => !!enter && css` animation: ${enterAnimation} 300ms ease; `}
    user-select: none;
`;

export const SubTitle = styled.div`
    color: ${({ theme }) => theme.color.secondary};
    font-size: ${({ theme }) => theme.fontSize.small};
`;

const HomePage: FunctionComponent<HomePagePropsType> = () => {
    const [displayedCard, setDisplayedCard] = useState<number>(0);

    // const animations = useMemo<AnimationStructureType>(() => ({}), []);
    const [ animations, setAnimations ] = useState<AnimationStructureType>({});

    const swipeToCard = useCallback((eventData: EventData) => {
        if (eventData.dir === 'Right') {
            goLeft(displayedCard, animations, setAnimations, setDisplayedCard);
        }
        if (eventData.dir === 'Left') {
            goRight(displayedCard, animations, setAnimations, setDisplayedCard);
        }
    }, [displayedCard, setDisplayedCard, data]);

    return (
        <Container>
            <SEO
                title="Декларации"
                description="Декларации"
            />
            <Typography>Декларации {formatNumber(displayedCard + 1)} / {data.length}</Typography>
            <Swipeable onSwiped={swipeToCard}>
                <Cards>
                    {data.map((item, index) => (
                        <CustomCard key={item.title} visible={index === displayedCard} leaveRight={animations[index] && animations[index].type === 'leaveRight'} leaveLeft={animations[index] && animations[index].type === 'leaveLeft'} enter={animations[index] && animations[index].type === 'enter'}>
                            <CardContent>
                                <SubTitle>Я говорю себе</SubTitle>
                                &#x201c;{item.title}&#x201d;
                                <Description>
                                    {chop(item.description)}
                                </Description>
                            </CardContent>
                        </CustomCard>
                    ))}
                </Cards>
            </Swipeable>
            <Buttons>
                <Button variant="contained" color="primary" onClick={() => goLeft(displayedCard, animations, setAnimations, setDisplayedCard)}>&larr; Обратно</Button>
                <Button variant="contained" color="primary" onClick={() => goRight(displayedCard, animations, setAnimations, setDisplayedCard)}>Туда &rarr;</Button>
            </Buttons>
            <br />
        </Container>
    );
};

export default HomePage;

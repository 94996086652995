import React, { FunctionComponent } from 'react';

import {
    DescriptionRoot,
} from './style';
import { DescriptionPropsType } from './type';

export const Description: FunctionComponent<DescriptionPropsType> = ({
    children,
    ...restProps
}) => {
    return (
        <DescriptionRoot {...restProps}>
            {children}
        </DescriptionRoot>
    );
};

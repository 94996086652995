export const data = [
    {
        title: 'Я действую одновременно с планированием и обучением.',
        description: `
Когда:
Начинаю копить стопку статей для чтения перед тем как сделать действие.
Страшно действовать, не до конца разобравшись с предметом.
`,
    },
    {
        title: 'Я понимаю, что планирование и обучение это не действие.',
        description: `
Когда:
Появляется чувство законченности дела после прохождения обучения или сделанного планирования.
        `,
    },
    {
        title: 'Я понимаю, что планирование пожирает энергию, тогда как сделанное дело - ее добавляет.',
        description: `
Когда:
Чувствую, что много чего изучил, а силы на исходе.
        `,
    },
    {
        title: 'Я всегда предъявляю себя и свои результаты окружающим.',
        description: `
Когда:
Включается самозванец, который говорит мне, что я еще недостаточно компетентен.
Я думаю, что тема не будет кому-то интересна.
`,
    },
    {
        title: 'Если я что-то очень хочу сделать, я делаю, это мое право.',
        description: `
Когда:
Когда мне кто-то говорит, что идея не сработает, или что она ничего не стоит.
`,
    },
    {
        title: 'Я подписываюсь только на то, чего я реально хочу.',
        description: `
Когда:
Мне предлагают что-то, чего я на самом деле внутренне не желаю или не принимаю.
`,
    },
    {
        title: 'Я не принимаю проблему как данность, я ищу решение.',
        description: `
Когда:
Я сталкиваюсь с проблемой и начинаю психовать или впадаю в уныние, вместо того, чтобы поискать решение.
`,
    },
    {
        title: 'Если появился страх, нужно с ним работать: признать, распознать, преодолеть.',
        description: `
Когда:
Появляется страх перед выходом из Зоны Комфорта.`,
    },
    {
        title: 'Я осознаю, что я взрослый человек и что все, что есть в мире, доступно мне.',
        description: `
Когда:
Появляется чувство, что вещь или дело не для меня, что я еще "не дорос" до такого.
Появляется чуство, что я "не потяну" то или иное дело.
`,
    },
    {
        title: 'Я планирую бюджет и являюсь финансовым якорем в своей семье.',
        description: `
Когда:
Появляется желание импульсивно потратить кучу денег.
Появляется возможность куда-то поехать, но на последние деньги.`,
    },
    {
        title: 'Если выбранный подход не работает, я его меняю.',
        description: `
Когда:
Долго и упорно над чем-то работаю, а результата нет.
`,
    },
    {
        title: 'Я не веду себя как истекающий кровью заяц.',
        description: `
Когда:
Появляется желание жаловаться, изображая из себя жертву.
`,
    },
    {
        title: 'Я не завидую другим, я искренне радуюсь их успехам. Я должен быть следующим.',
        description: `
Когда:
Появляется зависть из-за успеха другого человека.
`,
    },
    {
        title: 'Я не раскрываю личные моменты малознакомым людям.',
        description: `
Когда:
Появляется желание поделиться чем-то личным с малознакомым человеком только для того, чтобы расположить его к себе в беседе.
`,
    },
    {
        title: 'Я воспринимаю всех людей в жизни как учителей, которые мне даны, чтобы сделать себя лучше.',
        description: `
Когда:
Начинаю сравнивать себя с другими.
Начинаю вешать ярлыки.
Появляется желание посмеяться над другим человеком.
Появляется чувство превосходства над другим человеком.
Появляется зависть от уровня полета другого человека.
Появлятеся разочарование в себе от того, что во мне нет чего-то, что есть в другом человеке.
`,
    },
    {
        title: 'Я составляю полный список договоренностей письменно, прежде чем сказать "да".',
        description: `
Когда:
Появляется желание договориться о чем-то формальном, а детали решить по ходу дела.

Я тщательно обдумываю, прежде чем что-то пообещать, а если пообещал, то делаю.
`,
    },
    {
        title: 'Я ищу любую возможность для того, чтобы познакомиться с новыми людьми.',
        description: `
Я развиваю навык нетворкинга и изучаю людей, чтобы понять их проблемы.
`,
    },
    {
        title: 'Я стараюсь контролировать свои эмоции и никогда, никогда психами не выношу мозг своим близким.',
        description: `
Когда:
Появляется желание на кого-то наорать.
Появляется желание психануть и начать истерить.
`,
    },
    {
        title: 'Я ориентирован на результат, а не на процесс. Результат это проект, который можно показать людям.',
        description: `
Когда:
Хочется покодить какой-нибудь генератор, революционную библиотечку или любую другую заведомо мертворожденную поделку, которая не вытекает органически из какого-либо проекта, дошедшего до продакшна.
`,
    },
    {
        title: 'Я ловлю момент, когда я начинаю получать удовольствие от процесса, и пресекаю это.',
        description: `
Когда:
Начинаю вдрачиваться в детали и решать несуществующие проблемы.
`,
    },
    {
        title: 'Я держу удар.',
        description: `
Когда хочется распсиховаться, бросить все и убежать. Предпринимательство это умение держать удар.
Однако, солому я не забываю подстелить.
`,
    },
    {
        title: 'Я мыслю и действую в первую очеред в интересах себя и близких мне людей.',
        description: `
`,
    },
    {
        title: 'Я делаю свой research и обладаю полнотой информации, чтобы быть готовым.',
        description: `
Я также доверяю своему выбору.
`,
    },
//     {
//         title: 'Каждый месяц я делаю то, чего раньше не делал',
//         description: `
// Иду туда, где раньше не был, общаюсь с теми, с кем раньше не общался.
// Я нахожусь в зоне дискомфорта и роста.
// `,
//     },
//     {
//         title: 'Я принимаю решения, а не хожу вокруг да около',
//         description: `
// Я беру паузу в 2 дня, а потом говорю "да" или "нет".
// Если сказано "да", то я уже не поворачиваю назад.
// `,
//     },
];
